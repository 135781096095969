<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- 回頂端 -->
    <a id="toTop" class="toTop icon iconButton" @click.prevent="toTop()">
      <font-awesome-icon icon="fa-solid fa-chevron-up"></font-awesome-icon>
    </a>
    <!-- Header -->
    <section class="header index">
        <a class="left" @click.prevent="popupGuestMode">
            <img class="logo" src="@/assets/img/favicon.svg">
            <span class="primary">0</span>
        </a>
        <img src="@/assets/img/title.svg">
        <div class="right">
            <a class="icon iconButton" @click.prevent="popupGuestMode">
                <!-- <font-awesome-icon icon="fa-solid fa-barcode" size="xl"></font-awesome-icon> -->
                <img  src="@/assets/img/barcode.svg">
            </a>
            <!-- <NotificationBell
                @count-unread="countUnread = $event"
                @get-point="getPointHandler"
            /> -->
            <a class="icon iconButton" @click.prevent="popupGuestMode">
                <img src="@/assets/icon/bell.svg" />
                <div class="new">
                    <span class="fXS white"> 0 </span>
                </div>
            </a>
        </div>
    </section>
    <!-- Main -->
    <section ref="main" class="main index">
      <!-- 功能 -->
      <div class="function flexH width padding hide">
        <a class="flexV center width" @click.prevent="popupGuestMode">
          <img src="@/assets/icon/record.svg" />
          <span class="fM">{{ $t('交易紀錄') }}</span>
          <!-- <span class="fS"><img src="@/assets/icon/point.svg">{{ displayUserPointBalance }}</span> -->
        </a>
        <a class="flexV center width" @click.prevent="popupGuestMode">
          <img src="@/assets/icon/ticket.svg" />
          <span class="fM">{{ $t('我的票券') }}</span>
          <!-- <span class="fS">{{ numberOfTickets }} {{ $t('張') }}</span> -->
        </a>
        <a
          class="flexV center width"
          @click.prevent="popupGuestMode"
        >
          <img src="@/assets/icon/stamp.svg" />
          <span class="fM">{{ $t('我的集章') }}</span>
          <!-- <span class="fS">{{ numberOfStampCards }} {{ $t('張') }}</span> -->
        </a>
      </div>
      <!-- Banner -->
      <div class="banners" id="banners" ref="banners" 
        @touchstart.stop="touchStart"
        @touchmove.stop="touchMove"
      >
        <div
          class="banner"
          v-for="(banner, index) in banners"
          :key="'banner' + index"
        >
          <a @click.prevent="openExternalUrl(banner.externalUrl)"
            ><img :src="banner.imageUrl"
          /></a>
        </div>

        <a v-if="banners && banners.length" class="prev" @click.prevent="plusSlides(-1, 0)">&#10094;</a>
        <a v-if="banners && banners.length" class="next" @click.prevent="plusSlides(1, 0)">&#10095;</a>
        <div class="dots" ref="dots">
          <span
            class="dot"
            v-for="(banner, index) in banners"
            :key="'dot' + index"
          ></span>
        </div>
      </div>
      <!-- store -->
      <a @click.prevent="popupGuestMode" class="tkMall">
        <img src="@/assets/img/tk-store.svg">
      </a>
      <!-- 任務 -->
      <div class="mission flexH width">
        <a
          class="title flexH middle width"
          @click.prevent="popupGuestMode"
        >            
            <img  class="icon" src="@/assets/icon/coin.svg">
            <span class=" third">獎勵有限 送完為止</span>
        
        </a>
        <a class="flexV center width" @click.prevent="popupGuestMode">
            <img src="@/assets/icon/service-b.svg">
            <span class="fM">縣政互動</span>
            <!-- <div class="hot">
                <span>HOT</span>
            </div> -->
        </a>
        <a
          class="flexV center width"
          @click.prevent="popupGuestMode"
        >
          <!-- class: none -->
          
          <span class="relative">
            <img  src="@/assets/icon/mission-c.svg" />                        
          </span>
          
          <span class="fM mt-0">縣民任務</span>
        </a>
        <a
          class="flexV center width"
          @click.prevent="popupGuestMode"
        >
          <img src="@/assets/icon/video.svg" />
          <span class="fM">{{ $t('看影片') }}</span>
        </a>
        <a
          class="flexV center width"
          @click.prevent="popupGuestMode"
        >
          <img src="@/assets/icon/game.svg" />
          <span class="fM">{{ $t('小遊戲') }}</span>
        </a>
      </div>
      
    
       
    </section>
    <!-- Popup (訪客提示) -->
    <section id="popVisit" class="pop visit">
        <div class="popBody">
            <Slider
                v-if="guestModeImages.length"
                v-model="introSliderValue"
                :speed="500"
                :interval="5000"
                :autoplay="false"
                :control-btn="guestModeImages.length > 1"
                :indicators="guestModeImages.length > 1 ? 'center': false"
                width="100%"
                height="100%"
                style="text-align: center;"
            >
                <SliderItem
                    v-for="(image, index) in guestModeImages"
                    :key="'image_' + index"
                >
                    <img :src="image.imageUrl">
                </SliderItem>
            </Slider>
            <!-- <img v-else src="@/assets/img/intro.png"> -->
            <div class="buttons">
                <button @click="guestModePopupClose"><span>略過</span></button>
                <button @click="goRegistration"><span>前往登入/註冊</span></button>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";
import { Slider, SliderItem } from "vue-easy-slider";

export default {
    name: "Guest",
    components: {
        Slider,
        SliderItem,
    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            slideIndex: 1,
            countUnread: 0,
            notifications: [],
            documents: [],
            firstNotification: {},
            banners: [],
            categoryType: "news", // "campaigns",
            autoPlay: true, // 自動輪播
            toggleTimer: true, // pause auto play
            delay: 3000, // 輪播時間
            intervalId: null,
            xDown: null, 
            yDown: null,
            isCampaignLoaded: false, 
            guestModeImages: [],
            introSliderValue: 0,
        };
    },
    // beforeRouteEnter(to, from, next) {
    //     // called before the route that renders this component is confirmed.
    //     // does NOT have access to `this` component instance,
    //     // because it has not been created yet when this guard is called!
    //     console.log(to, from);
    //     next((vm) => {
    //         let that = vm;
    //         if (that.user && that.user.userId) {
    //             //
    //         } else {
    //             next("/error");
    //         }
    //     });
    // },
    created() {
        this.getArticles();
    },
    computed: {
        ...mapState([
            "user",
            "userInfo",
        ]),
        ...mapGetters({
            articles: "article/getArticles"
        }),
        enableStamp(){
            return process.env.VUE_APP_ENABLE_STAMP ==='true' ;
        }
    },
    mounted() {
        this.showToTopBtn();
        this.getBanners()
        .then(() => {
            this.showCarousel();
        })
        this.getGuestModeImages();
    },
    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        document.body.style.overflow = 'scroll'; 
    },
    methods: {
        callArticleAPI() {
            var config = {
                method: 'get',
                url: `${this.apiHost}/articles/v1/merchants/${this.merchantId}/articles?category_type=${this.categoryType}`,
                headers: { }
            };

            return this.$http(config)
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getArticles() {
            this.callArticleAPI()
                .then(res => {
                    console.log("res: ", res);
                    this.$store.commit("article/setArticles", JSON.parse(JSON.stringify(res.data.articles)));
                })
                .catch(err => {
                    console.log("err: ", err);
                });
        },
        compare(a, b) {
            if (a.ts > b.ts) {
                return -1;
            } else if (a.ts == b.ts) {
                return 0;
            } else if (a.ts < b.ts) {
                return 1;
            }
        },
        tsToData(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD");
        },
        showSlides(n) {
            var i;
            var el = this.$refs["banners"];
            var slides = el.querySelectorAll("div.banner");
            var dotEl = this.$refs["dots"];
            var dots = dotEl.querySelectorAll("span.dot");
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            if (n > slides.length) {
                this.slideIndex = 1;
            }
            if (n < 1) {
                this.slideIndex = slides.length;
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active", "");
            }
            slides[this.slideIndex - 1].style.display = "flex";
            dots[this.slideIndex - 1].className += " active";
            this.toggleTimer = true;
        },
        plusSlides(n) {
            this.toggleTimer = false;
            this.showSlides((this.slideIndex += n));
            if (this.autoPlay && this.intervalId) {
                clearInterval(this.intervalId);
                this.autoPlayCarousel();
            }
        },
        swipedetect(el, callback) {
            var touchsurface = el, swipedir, startX, startY, distX, distY, elapsedTime, startTime,
            threshold = 50, //required min distance traveled to be considered swipe
            restraint = 100, // maximum distance allowed at the same time in perpendicular direction
            allowedTime = 300, // maximum time allowed to travel that distance
            handleswipe = callback || function(swipedir){ console.log('swipedir:'+ swipedir)}
            touchsurface.addEventListener('touchstart', function(e){
                var touchobj = e.changedTouches[0]
                swipedir = 'none'
                dist = 0
                startX = touchobj.pageX
                startY = touchobj.pageY
                startTime = new Date().getTime() // record time when finger first makes contact with surface
                e.preventDefault()
            }, false)
            touchsurface.addEventListener('touchmove', function(e){
                e.preventDefault() // prevent scrolling when inside DIV
            }, false)
            touchsurface.addEventListener('touchend', function(e){
                var touchobj = e.changedTouches[0]
                distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
                distY = touchobj.pageY - startY // get vertical dist traveled by finger while in contact with surface
                elapsedTime = new Date().getTime() - startTime // get time elapsed
                if (elapsedTime <= allowedTime){ // first condition for awipe met
                    if (Math.abs(distX) < threshold){
                        window.location = (el.children.item(slideIndex-1).href);
                        // console.log(el.children.item(slideIndex-1).href);
                    }
                    else if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ // 2nd condition for horizontal swipe met
                        swipedir = (distX < 0)? 'left' : 'right' // if dist traveled is negative, it indicates left swipe
                    }
                    else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ // 2nd condition for vertical swipe met
                        swipedir = (distY < 0)? 'up' : 'down' // if dist traveled is negative, it indicates up swipe
                    }}
                handleswipe(swipedir)
                e.preventDefault()
             }, false)
        },
        showCarousel() {
            this.showSlides(1);
            if (this.autoPlay) {
                this.autoPlayCarousel();
            }
            // var el = this.$refs["banners"];
            // this.swipedetect(
            //     el,
            //     function (swipedir) {
            //         // swipedir contains either "none", "left", "right", "top", or "down"
            //         if (swipedir == "left") this.plusSlides(1);
            //         if (swipedir == "right") this.plusSlides(-1);
            //     }.bind(this)
            // );
        },
        // swiper event(for mobile)
        touchStart (e) {
            this.xDown = e.touches[0].clientX;
            this.yDown = e.touches[0].clientY;
        },
        touchMove (e) {
            const _this = this;
            if (!this.xDown || !this.yDown) {
                return;
            }

            const xUp = e.touches[0].clientX;
            const yUp = e.touches[0].clientY;

            const xDiff = this.xDown - xUp;
            const yDiff = this.yDown - yUp;

            if (Math.abs(xDiff) > Math.abs(yDiff)) {
                xDiff > 0 ? _this.plusSlides(1, 0) :  _this.plusSlides(-1, 0);
            }
            this.xDown = null;
            this.yDown = null;
        },
        // 自動輪播
        autoPlayCarousel () {
            this.intervalId = setInterval(() => {
                if (this.toggleTimer) {
                    this.showSlides((this.slideIndex += 1));
                }
            }, this.delay);
        },
        showToTopBtn() {
            let target = this.$refs['main'];
            target.onscroll = function() {
                var top = target.scrollTop == 0;
                document.getElementById('toTop').style.display = top ? 'none' : 'flex';
            };
        },
        toTop() {
            let target = this.$refs['main'];
            target.scrollTo({top: 0, behavior: 'smooth'});
        },
        callBannerAPI() {
            let config = {
                url: `${this.apiHost}//banners/v1/merchants/yunlin/banners`,
                method: 'GET'
            }
            return this.$http(config);
        },
        getBanners() {
            return this.callBannerAPI()
            .then(callBannerAPIRes => {
                console.log("callBannerAPIRes: ", callBannerAPIRes);
                this.banners = callBannerAPIRes.data.banners;
                return;
            })
            .catch(callBannerAPIErr => {
                console.log("callBannerAPIErr: ", callBannerAPIErr);
            })
        },
        toArticle(articleId) {
            this.$router.push({path: `/guest/articles/${articleId}`});
        },
        openExternalUrl(externalUrl) {
            if(externalUrl) {
                window.open(externalUrl, '_blank');
            }
        },
        changeCetegoryOfArticle(type) {
            console.log("changeCetegoryOfArticle: ", type)
            this.categoryType = type;
            this.getArticles()
        },
        callGuestModeImagesAPI() {
            let config = {
                url: `${this.apiHost}/articles/v1/merchants/${this.merchantId}/images?type=visitor`
            };
            return this.$http(config);
        },
        getGuestModeImages() {
            this.callGuestModeImagesAPI()
                .then((res) => {
                    this.guestModeImages = res.data;
                })
                .catch((err) => console.log(err));
        },
        popupGuestMode() {
            // var popVisit = document.getElementById('popVisit');
            // popVisit.style.display = 'flex';
            // document.body.style.overflow = 'hidden';
            this.goRegistration();
        },
        guestModePopupClose() {
            var popVisit = document.getElementById('popVisit');
            popVisit.style.display = 'none';
            document.body.style.overflow = 'scroll';  
        },
        goRegistration() {
            // this.$router.push('/home');
            console.log('this.$router.query', this.$route.query)
            qcsso
                .init({
                    appId: process.env.VUE_APP_ID, // tycard staging man
                    params: { referralCode: this.$route.query.referralCode || "" }
                })
                .then(() => {
                    qcsso.login({
                        redirectUrl: `${process.env.VUE_APP_HOST}${process.env.BASE_URL}${this.$route.query.redirectPath || ''}`
                    });
                });
        }
    }
};
</script>

<style scoped src="@/assets/css/payment.css"></style>
<style>
 #popVisit .slider-btn{
    background: unset !important;
    height: unset !important;
    top: unset !important;
    bottom: 0 !important;
 }
 
 #popVisit .slider-btn-left {
    left: -20px !important;
 }
 
 #popVisit .slider-btn-right {
    right: -20px !important;
 }
 
 #popVisit .slider-icon {
    border-left: 2px solid #ef6079 !important;
    border-bottom: 2px solid #ef6079 !important;
    width: 10px !important;
    height: 10px !important;
 }
 
 #popVisit .slider-indicators {
    bottom: 0 !important;
 }
 
 #popVisit .slider-indicator-icon {
    width: 6px !important;
    height: 6px !important;
    margin: 5px !important;
 }
 
 #popVisit .slider-indicator-active {
    background-color: #ef6079 !important;
 }
</style>